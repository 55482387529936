import { provideStoreDevtools } from '@ngrx/store-devtools';
import dayjs from 'dayjs';
export const environment = {
  apiBaseUrl: 'http://192.168.28.54',
  port: ':3002',
  host:'http://192.168.28.54:3002',
  version: '/rest/v1/',
  isDev: false,
  googleAnalyticsUrl:
    'https://googleanalytics.palmchatbot.com/rest/v1/analytics',
  title: 'Dashboard',
  copyRightName:'Sanima Bank',
  startDate: dayjs().subtract(3, 'month').toISOString(),
  secret: '12345678901234567890123456789012',
  copyright: {
    name: 'Sanima Bank',
    logo: '/assets/brand/sanima.webp',
    url: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      website: '',
    },
    },
  production:true,
  providers: [],
cacheTime: 0,
};
